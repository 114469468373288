<template>
  <div class="flex flex-col h-full">
    <a
      href="#"
      @click.prevent="
        deactivated = !value;
        $emit('input', deactivated);
      "
      class="px-2 py-1 border mb-4 rounded self-start hover:text-white hover:border-white"
      :class="
        !deactivated
          ? 'text-red-500 hover:bg-red-500'
          : 'text-blue-500 hover:bg-blue-500'
      "
      v-show="title"
      >{{ title }}</a
    >
    <a
      v-if="
        addBtn &&
          permission &&
          can(
            permission.module,
            permission.subModule,
            permission.childModule,
            permission.operation,
            permission.options
          )
      "
      href="#"
      @click.prevent="callback && callback()"
      class="text-white px-2 py-1 rounded self-start bg-gray-500 hover:bg-gray-600 flex items-center space-x-2 mb-2"
    >
      <span v-html="icon"></span>
      <span>{{ addBtn }}</span></a
    >

    <nav
      v-if="!hideCategory && _.first(categories)"
      class="flex overflow-x-scroll space-x-2 mb-2 overflow-y-hidden"
    >
      <a
        v-for="(category, index) in ['Alle', ...categories]"
        :key="`category-${index}`"
        :tabindex="index"
        class="px-2 py-1 border rounded self-start text-xs space-x-2 mb-2"
        :class="
          currentCategory === category
            ? 'bg-blue-dark text-white'
            : 'text-blue-dark border-blue-dark'
        "
        @click.prevent="() => categoryHandler(category)"
        href="#"
      >
        {{ category }}
      </a>
    </nav>

    <h1 v-show="label" class="mb-4 text-base">{{ label }}</h1>
    <div class="h-full rounded border border-gray-200">
      <div
        class="border-b border-gray-200 rounded h-full table-parent relative"
        :class="height"
      >
        <table
          v-if="rows"
          class="min-w-full divide-y divide-gray-200 overflow-x-auto"
        >
          <thead class="bg-gray-50">
            <tr>
              <th
                v-for="(column, index) in columns"
                scope="col"
                :class="
                  `px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-${column.headerAlign}`
                "
                :key="`column-${index}`"
                :width="`${column.width}%`"
              >
                {{ column.label }}
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200 overflow-y-auto">
            <tr
              v-for="(row, row_index) in filteredRows"
              :key="`row-${row_index}`"
              class="border-b border-gray-200"
              :class="{ 'bg-gray-50': row_index % 2 }"
            >
              <td
                v-for="(column, column_index) in columns"
                :key="`data-${row_index}-${column_index}`"
                class="py-2 px-2 text-left font-bold"
              >
                <template v-if="column.type === 'callback'">
                  <a
                    href="#"
                    @click.prevent="column.callback(row)"
                    class="text-blue-900 cursor-pointer flex"
                  >
                    <div
                      v-if="row['archived']"
                      class="rounded-full h-2 w-2 bg-gray-200 m-1 ml-0"
                    ></div>
                    <div v-else class="rounded-full h-2 w-2 m-1 ml-0"></div>
                    <span
                      v-for="(field, index) in column.field"
                      :key="`${field}-${index}`"
                    >
                      {{ _.get(row, field) }}
                    </span>
                  </a>
                </template>
                <template v-else-if="column.type === 'link'">
                  <template v-if="row.type === 'pdf'">
                    <a
                      href="#"
                      @click.prevent="
                        $bus.$emit(column.event, {
                          endpoint: `/${resource ||
                            row.linkTo ||
                            column.linkTo}/${_.get(row, column.link)}/`,
                          obj: row,
                        })
                      "
                      class="text-blue-900 cursor-pointer flex"
                    >
                      <div
                        v-if="row['archived']"
                        class="rounded-full h-2 w-2 bg-gray-200 m-1 ml-0"
                      ></div>
                      <div
                        v-else-if="title"
                        class="rounded-full h-2 w-2 m-1 ml-0"
                      ></div>
                      <template v-if="column.field">
                        <span
                          v-for="(field, index) in column.field"
                          :key="`${field}-${index}`"
                          class="mr-1"
                        >
                          {{ _.get(row, field) }}
                        </span>
                      </template>
                    </a>
                  </template>
                  <template v-else>
                    <router-link
                      :to="
                        `/${column.linkTo || row.linkTo || resource}/${_.get(
                          row,
                          column.link
                        )}`
                      "
                      class="text-blue-900 cursor-pointer flex"
                    >
                      <div
                        v-if="row['archived']"
                        class="rounded-full h-2 w-2 bg-gray-200 m-1 ml-0"
                      ></div>
                      <div
                        v-else-if="title"
                        class="rounded-full h-2 w-2 m-1 ml-0"
                      ></div>
                      <template v-if="column.field">
                        <span
                          v-for="(field, index) in column.field"
                          :key="`${field}-${index}`"
                          class="mr-1"
                        >
                          {{ _.get(row, field) }}
                        </span>
                      </template>
                    </router-link>
                  </template>
                </template>
                <template v-else-if="column.type === 'link_access'">
                  <router-link
                    :to="
                      `/${row.linkTo || column.linkTo}/${_.get(
                        row,
                        column.link
                      )}`
                    "
                    class="text-blue-900 cursor-pointer flex"
                  >
                    <div
                      v-if="row['archived']"
                      class="rounded-full h-2 w-2 bg-gray-200 m-1 ml-0"
                    ></div>
                    <div
                      v-else-if="title"
                      class="rounded-full h-2 w-2 m-1 ml-0"
                    ></div>
                    <template v-if="column.field">
                      <span
                        v-for="(field, index) in column.field"
                        :key="`${field}-${index}`"
                        class="mr-1"
                      >
                        {{ _.get(row, field) }}
                      </span>
                    </template>
                  </router-link>
                </template>
                <template v-else-if="column.type === 'emit'">
                  <a
                    @click.prevent="$emit('selected', row)"
                    class="text-blue-900 cursor-pointer flex"
                  >
                    <div
                      v-for="(field, index) in column.field"
                      :key="`${field}-${index}`"
                      class="flex"
                    >
                      <div
                        v-if="row['archived']"
                        class="rounded-full h-2 w-2 bg-gray-200 m-1 ml-0"
                      ></div>
                      <div v-else class="rounded-full h-2 w-2 m-1 ml-0"></div>
                      <span>
                        {{ _.get(row, field) }}
                      </span>
                    </div>
                  </a>
                </template>
                <template
                  v-else-if="
                    column.type === 'actions' || column.type === 'checkbox'
                  "
                >
                  <div class="flex justify-between">
                    <i
                      v-for="(action, action_index) in column.actions"
                      :class="`${action.icon} cursor-pointer`"
                      @click="
                        () => {
                          action.callback(row.id);
                          updateDataTable();
                        }
                      "
                      :key="`action-${action_index}`"
                    ></i>

                    <div
                      v-if="
                        actions &&
                          permission &&
                          can(
                            permission.module,
                            permission.subModule,
                            permission.childModule,
                            permission.operation,
                            permission.options
                          )
                      "
                      class="flex justify-between"
                    >
                      <div
                        class="relative inline-block text-left self-end my-1 mr-2"
                      >
                        <span class="relative rounded z-10"
                          ><a
                            href="#"
                            class="inline-flex justify-center w-full px-2 text-xs leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded hover:text-gray-500 focus:outline-none active:bg-gray-50 active:text-gray-800"
                            type="button"
                            @click.prevent="toggle(row_index)"
                          >
                            <span>Aktion </span>
                            <svg
                              class="w-5 h-5 ml-2 -mr-1"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                              ></path></svg></a
                        ></span>
                        <div
                          class="relative transition-all duration-300 transform origin-top-right -translate-y-2 scale-95 z-50 inset-0"
                          v-show="current === row_index + 1"
                        >
                          <div
                            class="absolute right-0 w-36 mt-2 z-50 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded outline-none"
                          >
                            <a
                              v-if="actions.list.includes('edit')"
                              href="#"
                              class="text-gray-700 flex w-full px-2 py-1 text-sm leading-5 text-left hover:bg-gray-200"
                              @click.prevent="
                                () => {
                                  current = null;
                                  emitAction({ type: 'edit', payload: row });
                                }
                              "
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-5 w-5 mr-2"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                                />
                              </svg>
                              <span>Bearbeiten</span></a
                            >
                            <a
                              v-if="actions.list.includes('delete')"
                              href="#"
                              class="text-gray-700 flex w-full px-2 py-1 text-sm leading-5 text-left hover:bg-gray-200"
                              @click.prevent="
                                () => {
                                  current = null;
                                  emitAction({
                                    type: 'delete',
                                    payload: row,
                                  });
                                }
                              "
                              ><svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-5 w-5 mr-2"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                  clip-rule="evenodd"
                                /></svg
                              ><span>Entfernen</span></a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="flex m-1">
                        <input
                          :type="column.type"
                          @change="toggleSelection($event, row.id)"
                          class="h-4 w-4 text-indigo-600 focus:ring-blue-500 border-gray-300 rounded"
                        />
                      </div> -->
                  </div>
                </template>
                <template v-else-if="column.type === 'button'">
                  <div class="flex justify-center">
                    <template v-if="row['email']">
                      <div v-if="!row['user']">
                        <button
                          @click.prevent="column.callback(row.email)"
                          class="border rounded px-2 py-1 hover:bg-gray-100 hover:text-gray-700"
                        >
                          {{ column["deactivate"] }}
                        </button>
                      </div>
                      <span
                        class="border rounded px-2 py-1 font-normal"
                        v-else
                        >{{ column["activate"] }}</span
                      >
                    </template>
                  </div>
                </template>
                <template v-else-if="column.type === 'bool'">
                  <span
                    v-for="(field, index) in column.field"
                    :key="`${field}-${index}`"
                    class="px-2 py-1 rounded text-white"
                    :class="_.get(row, field) ? 'bg-green-500' : 'bg-gray-400'"
                  >
                    {{ _.get(row, field) ? "Aktiv" : "Inaktiv" }}
                  </span>
                </template>
                <template v-else-if="column.bool === 'bool'">
                  <span
                    v-for="(field, index) in column.field"
                    :key="`${field}-${index}`"
                    class="px-2 py-1 rounded text-white"
                    :class="_.get(row, field) ? 'bg-gray-400' : 'bg-green-500'"
                  >
                    {{ _.get(row, field) ? "Inaktiv" : "Aktiv" }}
                  </span>
                </template>
                <template v-else-if="column.type === 'status'">
                  <span
                    v-for="(field, index) in column.field"
                    :key="`status-${field}-${index}`"
                    class="py-1 rounded"
                    :class="column.class ? column.class : 'text-green-500'"
                  >
                    {{ statusTranslate(_.get(row, field)) }}
                  </span>
                </template>
                <template v-else-if="column.type === 'image'">
                  <div class="flex relative dash">
                    <div
                      v-for="(field, index) in column.field"
                      :key="`image-${field}-${index}`"
                      class="w-6 h-6"
                    >
                      <div v-if="_.has(row, field)" class="w-6 h-6">
                        <template v-if="!_.isNil(_.get(row, field))">
                          <img
                            :src="_.get(row, field)"
                            :class="
                              `rounded-full w-6 h-6 object-cover absolute left-${index *
                                5}`
                            "
                            :alt="
                              _.get(row, `prepared_by[${index}].personal.name`)
                            "
                            v-tooltip="
                              _.get(row, `prepared_by[${index}].personal.name`)
                            "
                          />
                        </template>
                        <template
                          v-else-if="
                            _.get(row, `prepared_by[${index}].personal.name`) ||
                              _.get(
                                row,
                                `prepared_by[${index}].personal.initial`
                              )
                          "
                        >
                          <div
                            :class="
                              `rounded-full w-6 h-6 absolute left-${index * 5}`
                            "
                          >
                            <avatar
                              :username="
                                _.get(
                                  row,
                                  `prepared_by[${index}].personal.initial`
                                ) ||
                                  _.get(
                                    row,
                                    `prepared_by[${index}].personal.name`
                                  )
                              "
                              :size="24"
                              v-tooltip="
                                _.get(
                                  row,
                                  `prepared_by[${index}].personal.name`
                                )
                              "
                            />
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </template>

                <div class="relative" v-else-if="column.type === 'preview'">
                  <a
                    class="py-1 text-blue-dark cursor-pointer"
                    @mouseenter="previewLink(_.get(row, column.link))"
                    @mouseleave="clearTimeout"
                    :href="_.get(row, column.link)"
                    target="_blank"
                  >
                    {{ _.get(row, column.field) }}

                    <div
                      class="inset-0 bg-white rounded-lg z-50 shadow d-none"
                      :class="
                        showFull ? 'fixed mx-64 my-24' : 'mt-8 absolute h-64'
                      "
                      v-show="preview && previewUrl === _.get(row, column.link)"
                    >
                      <a
                        href="#"
                        @click.prevent="
                          preview = false;
                          previewUrl = null;
                          showFull = false;
                        "
                        class="absolute top-1 right-1"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </a>

                      <div class="py-5 h-full">
                        <iframe :src="previewUrl" width="100%" height="100%" />
                      </div>

                      <a
                        href="#"
                        @click.prevent="showFull = true"
                        v-show="!showFull"
                        class="absolute right-2 bottom-3 rounded-full bg-gray-200 items-center px-4 py-1 flex space-x-2"
                      >
                        <span
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"
                            />
                            <path
                              d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"
                            />
                          </svg>
                        </span>
                        <span>Show Full</span>
                      </a>
                    </div>
                  </a>
                </div>
                <div v-else-if="column.type === 'itext'">
                  {{ column.field }} - {{ row }} - {{ row_index }}
                  <!-- {{ _.get(row, `${column.field[0][row_index].column.field[1]}`) }} -->
                </div>
                <div v-else-if="column.type === 'idate'"></div>
                <template v-else>
                  <span
                    v-for="(field, index) in column.field"
                    :key="`${field}-${index}`"
                    :class="column.type === 'date' && row.expired"
                    :style="{
                      'white-space':
                        field === 'description' ? 'pre-line' : 'initial',
                    }"
                  >
                    {{
                      (field.includes("date") || column.type === "date") &&
                      _.get(row, field)
                        ? moment(_.get(row, field), "YYYY-MM-DD").format(
                            "DD.MM.YYYY"
                          )
                        : _.get(row, field)
                        ? column.strlen
                          ? `${_.get(row, field).substr(
                              0,
                              column.strlen
                            )}${column["postfix"] && column["postfix"]}`
                          : `${translate(_.get(row, field))}${column[
                              "postfix"
                            ] && column["postfix"]}`
                        : "-"
                    }}
                  </span>
                </template>
              </td>
            </tr>
            <!-- More people... -->
          </tbody>
        </table>

        <div v-else class="text-center p-2">Keine Daten verfügbar.</div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import ClickOutside from "vue-click-outside";

export default {
  name: "data-repeater",
  directives: {
    ClickOutside,
  },
  props: [
    "label",
    "title",
    "icon",
    "actions",
    "addBtn",
    "columns",
    "rows",
    "searchIn",
    "show",
    "resource",
    "callback",
    "value",
    "permission",
    "height",
    "hideCategory",
  ],
  components: {},
  data() {
    return {
      hover: null,
      showFull: false,
      preview: false,
      previewUrl: null,
      selections: [],
      context: [],
      current: null,
      deactivated: false,
      currentCategory: "",
    };
  },
  created() {
    this.updateQuery(null);
    this.updateFilters(null);
  },
  mounted() {
    this.init();
  },
  computed: {
    categories() {
      return _.compact(_.uniq(_.map(this.rows, (row) => row.categorie)));
    },
    filteredRows() {
      if (this.currentCategory && this.currentCategory !== "Alle")
        return this.rows.filter(
          (row) => row.categorie === this.currentCategory
        );
      else return _.sortBy(this.rows, ["categorie"]);
    },
  },
  methods: {
    outside() {
      this.current = null;
    },
    init() {
      this.currentCategory = "Alle";
      this.deactivated = this.value;
    },
    updateDataTable() {
      setTimeout(() => {
        this.$emit("reload");
      }, 500);
    },
    toggleSelection(e, id) {
      if (e.target.checked) {
        this.selections.push(id);
        this.selections = _.uniq(this.selections);
      } else this.selections = _.xor(this.selections, [id]);

      this.$forceUpdate();
    },
    categoryHandler(category) {
      this.currentCategory =
        category !== this.currentCategory && category !== "Alle"
          ? category
          : "Alle";
    },
    toggle(index) {
      this.current = this.current !== index + 1 ? index + 1 : null;
    },
    close() {
      this.current = null;
    },
    emitAction(state) {
      this.context = null;
      state = { ...state, type: state.type ? state.type : "get" };
      this.actions.callback(state);
    },
    previewLink(link) {
      // check if link is a download link or preview link
      const fileExtensions = [
        "pdf",
        "jpg",
        "jpeg",
        "png",
        "zip",
        "doc",
        "docx",
        "xls",
        "xlsx",
      ];

      // Extract the file extension from the link
      const extension = link.split(".").pop();

      // Check if link is a download link
      if (fileExtensions.includes(extension)) {
        console.log("This is a download link");
        return;
      }

      // check if previewUrl is the same as link then retrun
      if (this.previewUrl === link) return;
      this.hover = setTimeout(() => {
        this.previewUrl = link;
        this.preview = true;
      }, 1000);
    },
    clearTimeout() {
      window.clearTimeout(this.hover);
    },
  },
  watch: {
    rows: {
      handler: function() {
        //this.currentCategory = 'Alle'
        this.context = _.map(this.rows, () => false);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style>
.vorbereitung-erledigt {
  background-color: #10b981;
}

.vorbereitung {
  background-color: #ffa200;
}

.abgeschlossen {
  background-color: #ff0000;
}
.dash::before {
  content: "-";
  position: absolute;
  left: 20px;
}
</style>
